import Vue from 'vue/dist/vue.esm'
import Lodash from 'lodash/lodash.min.js'
import TurbolinksAdapter from 'vue-turbolinks';
import Mousetrap from 'mousetrap';

import install_tippy from './install_tippy.js'

import moment from 'moment'
window.moment = moment

const clipboard = require("clipboard-polyfill");

// Mousetrap.bind('g i', function() { console.log('go to inbox'); });

// Vue.use(require('vue-moment'));
Vue.use(TurbolinksAdapter)

let loadedEvent = (window.Turbolinks === undefined) ? 'DOMContentLoaded' : 'turbolinks:load'

document.addEventListener(loadedEvent, () => {
  App.on_load();
  App.clipboard = clipboard;

  Mousetrap.bind(["?", 'f1'], function() {
    $('#shortcuts').modal({showClose: false});
  });
})
