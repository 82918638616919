import Vue from 'vue/dist/vue.esm'
import TurbolinksAdapter from 'vue-turbolinks'

import tippy from 'tippy.js'

Vue.use(TurbolinksAdapter)

$(document).on 'turbolinks:load', ->
  App.log 'turbolinks:load - header_undoredo.coffee'

  if $('#burger').length
    App.burger = new Vue(el: '#burger')

  if $('#header_undo_redo').length
    App.header = new Vue(
      el: '#header_undo_redo',

      created: ->
        App.log 'undo redo created!'

      data:
        undo_action: gon.undo_action
        redo_action: gon.redo_action
        tip1: null
        tip2: null

      computed:
        undo_enabled: ->
          @undo_action != null
        redo_enabled: ->
          @redo_action != null

      updated: ->
        @update_tooltips()

      mounted: ->
        @tip1 = tippy(this.$el.querySelector('a#undo'))
        @tip2 = tippy(this.$el.querySelector('a#redo'))
        @update_tooltips()

      methods:
        redo_tooltip: ->
          @redo_action or 'Already at latest change'

        undo_tooltip: ->
          @undo_action or 'No more changes to undo'

        update_tooltips: ->
          @tip1.setContent(@undo_tooltip())
          @tip2.setContent(@redo_tooltip())

        undo: (e) ->
          if @undo_enabled
            @_action 'undo'
          e.preventDefault()
          false

        redo: (e) ->
          if @redo_enabled
            @_action 'redo'
          e.preventDefault()
          false

        _action: (action_name) ->
          # App.log 'vue: ' + action_name
          that = this
          $.rails.ajax
            url: '/nodes/' + action_name
            method: 'post'

            beforeSend: ->
              App.show_progress()
            complete: (res) ->
              App.hide_progress()

            success: (res) ->
              that['undo_action'] = res.ui_status['undo_action']
              that['redo_action'] = res.ui_status['redo_action']
            error: (res) ->
              App.log "header - error: #{res}"
    )
