/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// import debug from 'debug';

import "@fontsource/lato";

App.debug = require('debug');
if (!!window.gon) {
  if (gon.js_logging) {
    App.debug.enable('*')
  } else {
    App.debug.disable()
  }
}

App.log = App.debug('app')

import "../components"

import './header_undoredo'
import './talk'
import './main'
import './upload'

App.morphdom = require('morphdom').default
