const Uppy = require('@uppy/core')
const XHRUpload = require('@uppy/xhr-upload')

document.addEventListener('turbolinks:load', () => {
  if($('#container').length && $('#talk_app').length == 0) {
    App.uppy = Uppy({
        autoProceed: false,
        restrictions: {
          maxFileSize: 50*1000*1000 // 50 MB
        }
      })
      .use(XHRUpload, {
        endpoint: "/upload",
        fieldName: "file",
        formData: true,
        getResponseError: (responseText, response) => {
          return new Error(`Upload error: ${App.strip_html(responseText)}`);
        }
      })
      .on('complete', (result) => {
        App.log('Upload result:', result)
      })
      .on('upload', (data) => {
        App.start_progress();
        Globals.progress_bar.stopTrickling();
      })
      .on('upload-progress', (file, progress) => {
        ratio = progress.bytesUploaded / progress.bytesTotal
        Globals.progress_bar.setValue(ratio)
      })
      .on('upload-success', (file, resp, uploadURL) => {
        App.show_noty_flash(`Uploaded ${file.name}`, 'success', 10000);
        App.hide_progress()
      })
      .on('upload-error', (file, error) => {
        App.show_noty_flash(error.message, 'error');
        App.hide_progress()
      })
  }
})
