import Vue from 'vue/dist/vue.esm'
import Lodash from 'lodash/lodash.min.js'
import TurbolinksAdapter from 'vue-turbolinks';

Vue.use(require('vue-moment'));
Vue.use(TurbolinksAdapter)

document.addEventListener('turbolinks:load', () => {
  if($('#talk_app').length) {
    App.log('talk/index.html');
    App.talk_app = new Vue({
      el: '#talk_app',
      data: function() {
        return {
          messages: gon.messages,
          user_id: _.parseInt(App.current_user_id()),
          text: '',
          connected: App.talk.consumer.connection.isActive(),
          history_index: -1
        }
      },

      computed: {
        ordered_messages: function () {
          return _.orderBy(this.messages, ['ref'], ['asc'])
        },

        is_disabled() {
          return !this.text || !this.connected;
        },

        user_messages: function () {
          var context = this;
          return _.filter(this.ordered_messages, function(message) { return message.user_id === context.user_id; })
        },

        history: function() {
          return _.map(this.user_messages, function(m) { return m.raw_text; })
        },

        last_history_index: function() {
          return this.history.length - 1;
        },

        history_text: function() {
          return this.history[this.history_index];
        }
      },

      methods: {
        send: function() {
          if (!this.is_disabled) {
            let id = App.talk_app.next_id()

            App.talk.message(App.talk_app.text, id)

            let message = {
              text: App.talk_app.text,
              raw_text: App.talk_app.text,
              user_id: App.talk_app.user_id,
              ref: id,
              created_at: App.talk_app.$moment().format(),
              href: null
            }
            App.talk_app.messages.push(message)
            App.talk_app.$refs.text.focus()
            App.talk_app.text = ''
            App.talk_app.history_index = App.talk_app.last_history_index;

            App.talk_app.scroll_to_last()
          }
        },

        history_up: function() {
          if(this.history.length) {
            this.text = this.history_text;
            if((this.history_index -= 1) < 0) {
              this.history_index = this.last_history_index;
            }
          }
        },

        history_down: function() {
          if(this.history.length) {
            if((this.history_index += 1) >= this.history.length) {
              this.history_index = 0;
            }
            this.text = this.history_text;
          }
        },

        scroll_to_last: function() {
          $(this.$el).find('.list ul li:last-child()').trigger('scrollIntoView', true)
        },

        is_scrolled_to_bottom: function() {
          const scrollBottomHeight = 200;
          return ($(window).scrollTop() + $(window).height() > $(document).height() - scrollBottomHeight);
        },

        next_id: function() {
          return App.uuid + '-' + Date.now();
        },

        add_message: function(message) {
          if(App.talk_app.is_scrolled_to_bottom()) {
            App.talk_app.scroll_to_last();
          } else {
            // App.log('ignore scroll as not at bottom');
          }

          App.talk_app.messages.push({
            text: message.text,
            raw_text: message.raw_text,
            user_id: message.user_id,
            user_name: message.user_name,
            ref: message.ref,
            created_at: message.created_at,
            href: message.href
          });
        },

        replace_message: function(message) {
          let existing_message = _.find(this.messages, function(m) { return m.ref === message.remote_reference });
          if(existing_message) {
            Object.assign(existing_message, {
              text: message.text,
              raw_text: message.raw_text,
              user_id: message.user_id,
              user_name: message.user_name,
              ref: message.ref,
              created_at: message.created_at,
              href: message.href
            });
          }
        },

        user_path: function(message) {
          return message.user_id ? "/users/" + message.user_id : '#'
        },

        user_avatar_path: function(message) {
          return this.user_path(message) + ".png";
        },

        messageClassObject: function(message) {
          let all = ''

          return all + ' ' + (message.user_id == this.user_id ? 'self-end talk--message-own' : 'self-start talk--message-other')
        }
      },

      mounted: function() {
        this.scroll_to_last()
        this.$refs.text.focus()
        this.history_index = this.history.length - 1  // -1 indicates no history
      }
    });
  }
})
