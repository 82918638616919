import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css'; // optional for styling
import 'tippy.js/themes/light-border.css';
import '../../components/users/tippy_override.css'

let loadedEvent = (window.Turbolinks === undefined) ? 'DOMContentLoaded' : 'turbolinks:load'

document.addEventListener(loadedEvent, () => {
  tippy('[data-tippy-template]', {
    content(reference) {
      const id = reference.getAttribute('data-tippy-template');
      const template = document.getElementById(id);
      return template.innerHTML;
    },
    allowHTML: true,
    arrow: true,
    interactive: true,
    placement: 'bottom',
    theme: 'light-border',
    trigger: 'click',
  });
})
